import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css'
import Footer from './Footer';
import { Analytics } from "@vercel/analytics/react";
import reportWebVitals from './reportWebVitals';
import { sendToVercelAnalytics } from './vitals';


ReactDOM.render(
  <React.StrictMode>
    <App/>
    <Analytics/>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals(sendToVercelAnalytics);
