import React from 'react';
import './index.css';

const podcastExamples = [
    { id: 1, title: "Financial Wellness Minute", description: "Teaching smart money habits", duration: "60 seconds", thumbnail: "images/money.png", audioSrc: "audio/ABFC_Minipods_FW_EP08Wk1_Planning for the Future.mp3" },
    { id: 2, title: "Black History Spotlight", description: "Celebrating Mae Jemison", duration: "60 seconds", thumbnail: "images/mae.png", audioSrc: "audio/EOA_EP19_Mae Jemison.mp3" },
    { id: 3, title: "Hispanic Heritage Heroes", description: "The story of Ellen Ochoa", duration: "60 seconds", thumbnail: "images/ellen.png", audioSrc: "audio/Ellen Ochoa.mp3" }
];

const impactNumbers = [
    { number: "200+", label: "Partnered Schools" },
    { number: "500K+", label: "Daily Reach" },
    { number: "2x", label: "Daily Air Plays" },
    { number: "1M", label: "Daily Impressions" },
    { number: "20M", label: "Monthly Impressions" }
];

const Header = () => (
    <header className="text-center py-16 bg-light-blue">
        <img src="images/logo.png" alt="Minipods Logo" className="mx-auto h-12 mb-8" />
        <h1 className="text-4xl md:text-6xl font-extrabold mb-4">Elevating Education, 60 Seconds at a Time</h1>
        <p className="text-lg text-gray-700 max-w-lg mx-auto mb-8">
            Engage students in seconds with our award-winning educational content.
        </p>
        <a href="mailto:info@playminipods.com" className="btn-primary inline-block">Get in Touch with Our Team</a>
    </header>
);

const TrustedBy = () => (
    <section className="text-center py-10">
        <h2 className="text-xl font-semibold mb-4 text-gray-800">Trusted By Schools and Organizations</h2>
        <div className="flex justify-center gap-8 flex-wrap">
            <img src="images/NYC_DOE_Logo.png" alt="NYC DOE Logo" className="h-16" />
            <img src="images/SACS logo.png" alt="SACS Logo" className="h-16" />
            <img src="images/Audible_logo.png" alt="Audible Logo" className="h-16" />
            <img src="images/yoto.png" alt="Yoto Logo" className="h-16" />
        </div>
    </section>
);

const ImpactNumbers = () => (
    <section className="bg-white py-12">
        <h2 className="text-3xl font-semibold text-center text-primary-blue mb-8">Our Reach, Your Impact</h2>
        <div className="grid grid-cols-2 md:grid-cols-5 gap-6 px-4 text-center">
            {impactNumbers.map((item, index) => (
                <div key={index} className="card p-6">
                    <div className="text-4xl font-bold mb-2">{item.number}</div>
                    <div className="text-sm text-gray-600">{item.label}</div>
                </div>
            ))}
        </div>
    </section>
);

const SampleEpisodes = () => (
    <section className="py-20 bg-white">
        <div className="container mx-auto text-center">
            <h2 className="text-4xl font-semibold mb-8 text-primary-blue">Sample Episodes</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 px-4 md:px-0">
                {podcastExamples.map((podcast) => (
                    <div key={podcast.id} className="card p-6 text-left shadow-lg rounded-lg">
                        <img src={podcast.thumbnail} alt={podcast.title} className="w-full h-40 object-cover rounded-lg shadow-md mb-4" />
                        <h3 className="text-xl font-bold text-primary-blue">{podcast.title}</h3>
                        <p className="text-sm text-gray-600">{podcast.description}</p>
                        <div className="text-sm text-gray-500 mt-2">{podcast.duration}</div>
                        <audio src={podcast.audioSrc} controls className="mt-2 w-full"></audio>
                    </div>
                ))}
            </div>
        </div>
    </section>
);

const Footer = () => (
    <footer className="py-12 bg-gray-50 text-center">
        <p className="text-lg font-semibold text-primary-blue mb-4">Ready to Bring Minipods to Your School or Organization?</p>
        <p className="text-gray-700 mb-6">Reach out to discover how Minipods can transform educational moments and create lasting impact. Connect with us to discuss partnership opportunities tailored to your community's needs.</p>
        <a href="mailto:info@playminipods.com" className="btn-primary">Contact Our Team</a>
        <p className="mt-4">© 2024 Minipods. All rights reserved.</p>
    </footer>
);
const WhyChooseMinipods = () => (
    <section className="py-20 bg-light-gray">
        <div className="container mx-auto text-center">
            <h2 className="text-4xl font-semibold mb-8 text-primary-blue">Why Choose Minipods</h2>
            <p className="text-lg text-gray-600 max-w-2xl mx-auto mb-12">
                Minipods are crafted to make a real difference in education, delivering high-quality, professionally produced content.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 px-4 md:px-0 text-left text-gray-700">
                <div>
                    <img src="images/pexels-yankrukov-8613319.jpg" alt="Why Choose Minipods" className="rounded-lg mb-4 w-full h-40 object-cover" />
                    <p className="mb-4">
                        Developed by ABF Creative, our award-winning podcasts captivate students with content that is both educational and inspiring. Minipods fit seamlessly into daily routines, providing students with invaluable life skills.
                    </p>
                </div>
                <div>
                    <img src="images/pexels-mikhail-nilov-8923258.jpg" alt="Impact Image" className="rounded-lg mb-4 w-full h-40 object-cover" />
                    <p>
                        From self-confidence to cultural heritage, our episodes are tailored to support educators and enhance classroom experiences. With an extensive reach, Minipods impact students across the nation.
                    </p>
                </div>
            </div>
        </div>
    </section>
);



const App = () => (
    <div>
        <Header />
        <TrustedBy />
        <ImpactNumbers />
        <SampleEpisodes />
        <WhyChooseMinipods />
        <Footer />
    </div>
);

export default App;
